import React from 'react'
import './styles.scss'

const Footer = () => {
	return (
		<div className="foot">
			&copy; 2021 Abigail Smith
		</div>
	)
}

export default Footer
